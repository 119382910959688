import axios from 'axios';
import { constructRequestHeaders, SERVER_URL } from "../helpers/request";

export default class MeetingService {
  baseUrl: string = '';

  constructor(url?: string) {
    this.baseUrl = url || `${SERVER_URL}/v1/meeting`;
  }

  async createMeeting(payload: any): Promise<any> {
    try {
      const response = await axios.post(`${this.baseUrl}`, payload, {
        headers: constructRequestHeaders({ tenantId: payload.tenantId })
      });
      return await Promise.resolve(response.data.data);
    } catch (error: any) {
      return await Promise.reject(error?.response?.data?.error || error);
    }
  }

  async createActivity(payload: any): Promise<any> {
    try {
      const response = await axios.post(`${this.baseUrl}/booking/live/join`, payload, {
        headers: constructRequestHeaders()
      })
      return await Promise.resolve(response.data.data);
    } catch (error: any) {
      return await Promise.reject(error?.response?.data?.error || error);
    }
  }

  async getDateAvailability(payload: any): Promise<any> {
    try {
      const response = await axios.get(`${this.baseUrl}/${payload.stylistId}`, {
        headers: constructRequestHeaders()
      })
      return await Promise.resolve(response.data.data);
    } catch (error: any) {
      return await Promise.reject(error?.response?.data?.error || error);
    }
  }

  async getTimeSlots(payload: any): Promise<any> {
    try {
      const response = await axios.get(`${this.baseUrl}/${payload.stylistId}`, {
        headers: constructRequestHeaders()
      })
      return await Promise.resolve(response.data.data);
    } catch (error: any) {
      return await Promise.reject(error?.response?.data?.error || error);
    }
  }
}