import React from 'react'


interface LabelProps {
  label: string;
  htmlFor: string;
  required?: boolean;
}

const Label: React.FC<LabelProps> = ({ label, htmlFor, required }) => {
  return (
    <label className="form-label" htmlFor={htmlFor}>
      {label}
      {required && <span className="text-contrast-500 ml-1">*</span>}
    </label>
  )
}
export default Label;