import React from "react";
import { useTranslation } from "react-i18next";

const PageTitle: React.FC = () => {
  const { t } = useTranslation();
  return (
    <h2 className="mb-4 mt-1 w-full">
      {t("BOOK_ELIGO")}
    </h2>
  );
};

export default PageTitle;
