
import React from "react";
import { RootState, useAppDispatch, useAppSelector } from "../store";
import { setGoogleAnalyticsInitalized } from "../store/features/analytics";
import ReactGa from "react-ga4";
const useGoogleAnalytics = () => {
    const { googleAnalyticsId, googleAnalyticsInitialized } = useAppSelector((state: RootState) => state.analytics)
    const dispatch = useAppDispatch();
    const event = React.useCallback((eventOptions: any) => {
        if (googleAnalyticsId) {
            ReactGa.event(eventOptions)
        }
    }, [googleAnalyticsId]);

    React.useEffect(() => {
        if (!googleAnalyticsInitialized && googleAnalyticsId) {
            ReactGa.initialize(googleAnalyticsId);
            ReactGa.send({
                hitType: "pageview",
                page: window.parent.location.href
            })
            dispatch(setGoogleAnalyticsInitalized(true));
        }
    }, [googleAnalyticsInitialized, dispatch, googleAnalyticsId]);

    return {
        event
    }
};

export default useGoogleAnalytics;