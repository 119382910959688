import classNames from 'classnames';
import React, { MouseEventHandler } from 'react';

interface ButtonProps {
  style?: React.CSSProperties;
  children: React.ReactNode;
  onClick?: MouseEventHandler;
  type?: 'button' | 'submit';
  className?: string;
  disabled?: boolean;
  full?: boolean;
}

const Button: React.FC<ButtonProps> = ({ children, type, onClick, disabled, full, style, className }) => {
  return <button type={type ? type : 'button'} onClick={onClick} disabled={disabled} style={style} className={classNames("button", className, {
    "button--disabled": disabled,
    "button--full": full
  })}>
    {children}
  </button>
}
export default Button;