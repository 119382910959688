import dayjs, { Dayjs } from "dayjs";
import { dayCycleRanges } from "../constants/time";
import { DayCycleTimeSlots, Time, TimeSlot } from "../interfaces/time";

export const constructTimeSlots = (
  startTime: Time,
  endTime: Time,
  slotDuration: number,
  selectedDate: Date,
  firstSlotDiff: number
): TimeSlot[] => {
  if (slotDuration < 1) return [];

  let dayjsDate: Dayjs = dayjs().add(0, "d");
  let isSelectedDayToday = dayjs(selectedDate).isSame(dayjsDate, "date");
  if(isSelectedDayToday) {
    dayjsDate = dayjsDate.add(firstSlotDiff, 'hours');
  }
  // Round up to next 0,30 min if slotduration is 30mins or 0 if slotDuration is 60mins
  if(dayjsDate.minute() !== 0) {
    dayjsDate = dayjsDate.set("minute", (Math.ceil(dayjsDate.minute()/slotDuration) * slotDuration) % 60);
    if(dayjsDate.minute() === 0) dayjsDate = dayjsDate.add(1, "hours");
  }

  let currentDayjsDate: Dayjs = dayjsDate
  .set("hours", startTime.hours)
  .set("minutes", startTime.minutes);
  const limitDayjsDate: Dayjs = dayjsDate
    .set("hours", endTime.hours)
    .set("minutes", endTime.minutes);

  if(isSelectedDayToday && currentDayjsDate.isBefore(dayjsDate)) {
    currentDayjsDate = dayjsDate;
  }

  const slots: TimeSlot[] = [];

  while (currentDayjsDate.isBefore(limitDayjsDate)) {
    slots.push({
      startTime: currentDayjsDate.format("HH:mm"),
      duration: slotDuration,
      disabled: false,
    });
    currentDayjsDate = currentDayjsDate.add(slotDuration, "minutes");
  }

  return slots;
};

export const constructDayCycleTimeSlots = (
  slotDuration: number = 60,
  firstSlotDiff: number,
  selectedDate: Date
): DayCycleTimeSlots[] =>
  Object.entries(dayCycleRanges).map(([key, value]: any) => ({
    id: key,
    slots: constructTimeSlots(value.startTime, value.endTime, slotDuration,selectedDate, firstSlotDiff),
  }));
